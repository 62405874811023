import { IEnv } from './env.type'

const hostProd = 'api.phoenix-garden.com'
const localPath = window.location.hostname.replace('admin', 'api')
const currentPatch = window.location.hostname === 'localhost' ? hostProd : localPath

export const environment: IEnv = {
  PRODUCTION: true,
  API_PREFIX: `https://${currentPatch}/`,
  WEBSOCKET_PREFIX: `wss://${currentPatch}`,
  CABINET_PREFIX: `https://${currentPatch.replace('api', 'my')}`,
}
