import { environment as prodEnv } from './environment.prod'
import { environment as sandboxEnv } from './environment.sandbox'
import { IEnv } from './env.type'

const getEnv = (): IEnv => {
  console.log(`Current env: ${process.env.REACT_APP_STAGE}`)
  switch (process.env.REACT_APP_STAGE) {
    case 'production':
      return prodEnv
    case 'sandbox':
      return sandboxEnv
    case 'development':
    default:
      return prodEnv
  }
}

export const environment = getEnv()
